import Elections from "./elections.js"
import useDocumentTitle from './../../Head/DocumentTitle'

const History = () => {
	
	useDocumentTitle('History')
	return (
	  <>
		  <h1>History</h1>
		  <h2>Speaker of the House Ballots</h2>
		  <p>The table below shows each instance it has taken the House of Representatives more than one ballot to elect a Speaker of the House.</p>
		  <Elections />
	  </>
	);
  };
	
  export default History;
  