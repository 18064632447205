import React from "react";
import {
	MenuLogo,
	NavBar,
	NavMenu,
	MenuLink,
  } from './NavbarElements';

const Navbar = () => {
	return (
		<NavBar>
			<MenuLogo />
			<NavMenu>
				<MenuLink to="/" name="Home" />
				<MenuLink to="/clowns" name="Clowns" />
				{/* <MenuLink to="/history" name="History" /> */}
			</NavMenu>
		</NavBar>
	);
};

export default Navbar;