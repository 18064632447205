import PageLayout from "./components/PageLayouts"


function App() {
  return (
    <PageLayout></PageLayout>
  );
}

export default App;
