import history from "./elections.json"

export default function elections(){
	return (
		<table>
			<TableHeader />
			<TableContent />
		</table>
	)
}

const TableHeader = () => {
	return (
		<tr>
			<th class="date-start">Total Count</th>
			<th class="date-start">First Ballot</th>
			<th class="date-end">Last Ballot</th>
			<th class="date-duration">Duration (Days)</th>
			<th class="ballots">Ballots Cast</th>
			<th class="winner">Speaker Elected</th>
		</tr>
	);
}

const TableContent = () => {
	let i = 0;
	return (
		<>
		{history.map(function(data){
			i++;
			let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

			const date_start = new Date(data.start);
			const date_end = new Date(data.end);
			
			let diffTime = Math.abs(date_end - date_start);
			let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
			
			const formatted_date_start = date_start.toLocaleDateString("en-US", options);
			
			let formatted_date_end;
			let duration;
			if (!isNaN(diffDays)) {
				formatted_date_end = date_end.toLocaleDateString("en-US", options);
				duration = diffDays;
			} else {
				let today = new Date();

				let diffTime = Math.abs(today - date_start);
				let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
				formatted_date_end = "Is today the day?"
				duration = diffDays;
			}

			return (
				<tr>
					<td class="more-than-one">{i}</td>
					<td class="date-start">{formatted_date_start}</td>
					<td class="date-end">{formatted_date_end}</td>
					<td class="date-duration">{duration}</td>
					<td class="ballots">{data.ballots}</td>
					<td class="winner">{data.speaker}</td>
				</tr>
			)
		})}
		</>
	);
}