// useDocumentTitle.js
import { useRef, useEffect } from 'react'

function useDocumentTitle(title, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);

  let final_title = "HouseClowns.com ";
  if (title){
    // final_title += ;
  } else {
    final_title += ": " + title;
  }

  useEffect(() => {
    // document.title = "HouseClowns.com: " + title;
    document.title = final_title;
  }, [final_title]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, [])
}

export default useDocumentTitle