
import sites from "./sites.json"

export default function ClownNetwork() { 
	let hostname =window.location.hostname;
	return (
		<div class='clown-network'>Clown Network:
			{sites.map(function(site){
					const href = site.url + "\&ref\=" + hostname;
					return <a href={site.url + "/\&ref\=" + hostname} target='_BLANK' rel='noreferrer' >{site.title}</a>;

				})
			}
		</div>
	);
}

