import { BrowserRouter as Router, Routes, Route}
from 'react-router-dom'

	import Home from '../Page/Home';
	import History from '../Page/History';
	import Clowns from '../Page/Clowns';
	// import SignUp from '../Page/signup';
	// import Contact from '../Page/contact';
	
export default function content() { 
	return (
		<Router>
		<main>
		  <Routes>
			  {/* <Route exact path='/' element={<Home />} /> */}
			  <Route exact path='/' element={<History />} />
			  <Route path='/history' element={<History/>} />
			  <Route path='/clowns' element={<Clowns/>} />
			  {/* <Route path='/blogs' element={<Blogs/>} /> */}
			  {/* <Route path='/sign-up' element={<SignUp/>} /> */}
		  </Routes>
		</main>
	  </Router>
	);
  }
