import Disclaimer from "./Disclaimer"
import ClownNetwork from "./ClownNetwork"
export default function Footer() { 
	return (
	  <footer class="footer full-screen-element">
		<div>
			<p class="disclaimer">The Clowns have taken control of the House of Representatives. Laughing or crying, we're all watching.</p>
			<Disclaimer />
			<ClownNetwork />
		</div>
	  </footer>
	);
  }