
import Header from "../Header"
import Content from "../Page"
import Footer from "../Footer"

import "../../assets/styles.scss";

import GoogleTagManager from '../GoogleTagManager';
GoogleTagManager();

export default function Layout() {
	return (
		<>
			<Header />
			<Content />
			<Footer />
		</>
	);
  }


