// import Typogrophy from '../components/Typogrophy';
  
const Home = () => {
  return (
    <div>
        <h1>Home Page</h1>
    </div>
  );
};
  
export default Home;
