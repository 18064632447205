import useDocumentTitle from './../../Head/DocumentTitle';

const Clowns = () => {
	useDocumentTitle('Clowns');
	return (
	  <>
		  <h1>Clowns</h1>
		  <p>The GOP is a circus. We're working on building profiles for each clown.</p>
	  </>
	);
  };
	
export default Clowns;